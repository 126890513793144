import { IProduct, IStoreProduct } from "../models/api/IProduct";

export type ProductModalMessage = {
    type: "error" | "info";
    text: string;
};
export type ProductModalItem = {
    message?: ProductModalMessage;
    products: IProduct[];
    hasParams?: boolean;
};

export type ProductModalInput = {
    message?: ProductModalMessage;
    product?: IStoreProduct | IProduct;
    hasParams?: boolean;
};

export function isProductModalInput(obj: ProductModalInput | IStoreProduct | IProduct): obj is ProductModalInput {
    return (<ProductModalInput>obj).product !== undefined || (<ProductModalInput>obj).message !== undefined;
}
