import React from "react";
import { Button, ButtonProps, Spinner } from "react-bootstrap";

interface IProps {
    children?: React.ReactNode;
    isLoading: boolean;
    icon?: React.ReactNode;
}

export const LoadingSpinnerButton = ({ children, isLoading, icon, className, ...props }: IProps & ButtonProps) => {
    return (
        <Button className={`d-flex align-items-center ${className}`} {...props}>
            {isLoading && <Spinner animation={"border"} size={"sm"} />}
            {!isLoading && icon && icon}

            {(icon && children) || (isLoading && children) ? <span className={"ms-2"}>{children}</span> : children}
        </Button>
    );
};
