import { useToast } from "hooks/use-toast";
import { useEffect } from "react";
import { Toast, ToastContainer } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { useAppSelector } from "store/hooks";
import { selectToasts } from "store/slices/uiSlice";
import { AnimatePresence, motion } from "framer-motion";

import "./toasts.scss";
import { CartzillaIcon, CartzillaIconName } from "components/ui/icons/cartzilla-icon";

export const Toasts = () => {
    const toasts = useAppSelector(selectToasts);
    const location = useLocation();
    const { removeToast, clearToasts } = useToast();

    useEffect(() => {
        clearToasts();
    }, [location.pathname]);

    return (
        <div className={"toasts-wrapper p-3"}>
            <ToastContainer>
                <AnimatePresence>
                    {toasts.map((toast) => (
                        <motion.div
                            key={toast.id}
                            transition={{ duration: 0.4 }}
                            initial={{ opacity: 0, y: -20 }}
                            animate={{ opacity: 1, y: 0 }}
                            exit={{ opacity: 0, y: -20 }}
                        >
                            <Toast onClose={() => removeToast(toast)}>
                                <Toast.Header className={`bg-${toast.type} text-white d-flex gap-2`}>
                                    <CartzillaIcon icon={CartzillaIconName.Announcement} />
                                    {toast.title}
                                </Toast.Header>
                                <Toast.Body>{toast.text}</Toast.Body>
                            </Toast>
                        </motion.div>
                    ))}
                </AnimatePresence>
            </ToastContainer>
        </div>
    );
};
