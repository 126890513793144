export interface IProduct {
    id: number;
    name: string;
    code: string;
    categoryId: string;
    info: string;
    price: number;
    infoUrl: string | null;
    pdfUrl: string | null;
    hasImage: boolean;
    isAllowedForWeb: boolean;
    hasSpecificPrice: boolean;
    tag: string | null;
    attributes: string[];
    inStock: number | null;
    analogueProducts: number[];
}

export interface IStoreProduct extends IProduct {
    isSelected: boolean;
    timesOrdered: number;
    relatedProducts: number[];
    reminder: string | null;
}

export function isStoreProduct(product: IProduct | IStoreProduct): product is IStoreProduct {
    return (<IStoreProduct>product).relatedProducts !== undefined;
}
